import React from "react"
import { Element } from "react-scroll"
import { Modal, Button } from "antd"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExternalLink from "../components/external-link"
// import MailChimpSignUp from "../components/mailchimp-sign-up"

const candidates = [
  {
    name: "Alex Pellitteri",
    position: "DSA Co-Chair",
    bio: (
      <>
        <p>
          I joined DSA in 2017 when I was a Junior in high school after
          organizing for the Bernie Sanders campaign. At the time, my conception
          of politics was “ Democrats are good and Republicans are bad, but the
          Bernie campaign introduced me to the idea of democratic socialism and
          forced me to reevaluate my previous assumptions and aspirations. I
          realized that politics was not a competition between two parties, but
          rather a war between the powerful, domineering ruling class on one
          side and the working class who create all their wealth on the other.
          After Bernie’s campaign ended, I no longer saw the world the same and
          knew I had to keep organizing.
        </p>
        <p>
          In 2020, I was the campaign manager for DSA-endorsed Assemblymember
          Marcela Mitaynes. Her campaign began during the COVID-19 pandemic and
          ended during the George Floyd uprising. So we suddenly not only had to
          throw out the old organizing playbook and develop new and creative
          ways how to organize, we also had to develop a socialist electoral
          response to the intense political moment. It became our job to
          communicate that the root cause of these tragedies was capitalism and
          their only true solution was socialism. After managing Marcela’s
          campaign, I served as a member of the NYC-DSA Socialist in Office
          Committee, the coordinating body of our elected officials and their
          strategy which solidified my belief that DSA must achieve political
          independence from the Democrat. I saw how we can often default to the
          “liberal” legislative approach of passing legislation through personal
          relationships rather than mass action which often requires downplaying
          our socialist politics and a hesitancy to challenge powerful
          Democratic politicians. For our organizing to be effective, we need to
          actively present socialism as an alternative to the Democrats and
          Republicans.
        </p>
        <p>
          In YDSA, I helped start the Hunter College which is in NYC’s public
          university system. After adopting a campaign for ending tuition, our
          chapter grew exponentially. As a steering committee member of the
          campaign, I helped lead our efforts to talk to thousands of
          working-class CUNY students and demand a much-needed increase for CUNY
          in the New York state budget. The campaign helped grow NYC-YDSA to
          include five new YDSA chapters at CUNY and create countless socialist
          organizers. My experience starting a YDSA chapter demonstrated how
          crucial transparency and democracy are. Organizers must not just feel
          like participants, but feel ownership of the campaign and shape it’s
          political direction. Through YDSA I’ve helped start chapters from the
          ground up in difficult organizing conditions and have seen how having
          a campaign can exponentially grow a developing chapter. It underscored
          the importance of ensuring every DSA chapter has the resources to run
          sophisticated campaigns that develop leaders.
        </p>
        <p>
          I deeply believe that DSA can play a major role in ending capitalism
          and liberating the working class. However, this will only be possible
          if we can build a militant, democratic, and transparent organization.
        </p>
      </>
    ),
  },
  {
    name: "Sarah Hurd",
    position: "National Labor Commission",
    bio: (
      <>
        <p>
          My name is Sarah Hurd. I’ve been a member of Chicago DSA since 2017
          and a member of the National Labor Commission Steering Committee since
          February of this year. When I first joined DSA I bounced around,
          spending some time doing electoral work and leading my chapter’s
          Communications Committee. When I salted at a brewery in 2018, I was
          able to use many of the skills I had learned in DSA while gaining a
          much deeper understanding of how to run a campaign and build
          relationships on the shop floor. Organizing at my workplace was
          transformative for me and after the experience of building power on
          the shop floor I knew I wanted to make labor the focus of my
          organizing time. During the pandemic I was an officer in my local DSA
          chapter and spent those years trying to navigate holding everything
          together through Zoom calls and street protests. I was relieved when,
          starting with the Nabisco strike in 2021, myself and my chapter were
          able to direct our energy toward supporting labor work that connected
          us with other chapters. This was able to grow through the John Deere
          and Starbucks strikes and when the time came to pass one of the first
          Strike Ready resolutions, I helped draft, and build support for it. In
          February of this year I was invited to apply to join the NLC SC and
          after joining helped put on the Spring Conference that acted as a
          national debut for Strike Ready.{" "}
        </p>
      </>
    ),
  },
  {
    name: "Frances Reade",
    position: "Democracy Commission",
    bio: (
      <>
        <p>
          I joined DSA in a fit of rage on the day of Trump’s inauguration. The
          chapter I joined, the mighty East Bay DSA, was adding scores of
          members every month and had no structures in place to accommodate the
          influx. I took charge of coordinating our first bylaws rewrite
          process, which was an attempt to create a perfect DSA chapter on
          paper. Our plans were very clever and they worked horribly in
          practice. I was elected vice chair of the chapter, and oversaw a
          second bylaws rewrite the next year; this one created a minimal,
          flexible structure meant to allow the chapter to grow and change for
          years to come without the need for disruptive bylaws renegotiations.
          Through this process I learned so much about the principles of
          organizational reform, internal democracy, and the fact that (as a
          class enemy once correctly said), in any organization, “culture eats
          structure for breakfast.”
        </p>
        <p>
          I’m now a member of Detroit DSA, where I also have served on the
          steering committee and currently am co-chair of our Socialists in
          Office committee, which brings Rep. Rashida Tlaib and other socialist
          electeds together to coordinate local organizing projects. Earlier
          this year I co-chaired the committee that designed and implemented
          Detroit DSA’s first membership convention. I built the convention
          planning committee along the lines of the Democracy Commission – it
          was a multi-tendency space where comrades came together to discuss the
          problems and needs of the chapter and then built a convention where
          political debate and decision-making could help clarify what kind of
          group we want to be. Just as importantly, we worked together to build
          trust and to learn together about organizational democracy so that the
          convention would have broad legitimacy across the chapter.
        </p>
        <p>
          This experience, along with my personal commitment to being a friendly
          comrade and peacemaker, will heavily inform my approach to the
          Democracy Commission if I am elected. It’s critical that this body
          commit to working and learning together so that the recommendations we
          produce have broad excitement and buy-in from as much of the
          organization as possible!
        </p>
      </>
    ),
  },
  {
    name: "Danny Noest",
    position: "Democracy Commission",
    bio: (
      <>
        <p>
          I joined DSA in 2016 after being inspired by Bernie’s first
          presidential campaign. For the first few years, I was largely a paper
          member, happy to financially contribute to the work of others. In 2018
          I started to get involved with the NYC chapter, my participation
          ramping up hard as the second Bernie campaign took off and NYC ran its
          slate of candidates for state office. I did a lot of field at first,
          eventually expanding to help with data and tech work. I served a term
          on the Brooklyn Electoral Working Group OC as data co-coordinator.
          When the potential UPS strike approached I took what I learned in the
          electoral world to help with similar work on NYC's Union Power
          campaign, which was an amalgamation of several buckets of labor work
          leading towards a possible strike. Recently I moved up to the Hudson
          Valley, where I am active in the chapter’s labor working group. I am
          also active on the National Tech Committee, where I am working with
          others to further develop OpenSlides for use by chapters.
        </p>
        <p>
          I have also been active in organizing within the Bread and Roses
          Caucus. There, I have served on the organizing committee for the NYC
          chapter. As part of all of this work I’ve developed the ability to
          work across tendency lines and develop relationships to organize
          across and between groups. This includes working with other groups to
          find common ground and support around conventions, helping to mediate
          conflict that arises as a natural part of day to day organizing,
          serving as a conduit of information between groups, and organizing
          events. A big part of why I am running for democracy commission is for
          the chance to bring these skills to the body, which by design is going
          to need to find ways to build something approaching consensus across
          people representing all corners of the organization.
        </p>
      </>
    ),
  },
  {
    name: "Ramsin Canon",
    position: "Democracy Commission",
    bio: (
      <>
        <p>
          I have been a DSA member since 2016, and active on the left,
          particularly in labor, for twenty years (with some hiatuses!). In
          2008, I cofounded the anti-Olympic bid group No Games Chicago, which
          spearheaded the resistance to bringing the Olympics to my home city.
          In my time in DSA, I served as a chapter officer between 2016 and 2020
          and attended the 2017, 2019, and 2023 conventions. I helped launch the
          political education program and launched our Socialist Night School,
          with a focus on small group discussion and intensive facilitation. I
          drafted the facilitation manual that is widely used across DSA
          chapters. Between 2017 and 2019 I spearheaded the restructuring of our
          chapter, and served on the national DSA incorporation committee,
          helping new chapters form and adopt bylaws. I have also assisted
          numerous chapters struggling with structural reform and helped mediate
          conflicts between factions and tendencies within a number of chapters.
          Comradely, intensively political dialectical discussions, even more
          than debate, has been my focus since becoming active in 2016.
        </p>
        <p>
          My background in the labor movement–and within nonprofits–has heavily
          informed my interests in organization building, by negative example.
          Inverting the top-down structures of traditional US organizations is
          my main concern. Since 2016 I have published numerous articles on{" "}
          <ExternalLink href="https://midwestsocialist.com/2019/12/24/mass-organizations-and-ngos-in-a-socialist-movement/">
            NGOs
          </ExternalLink>
          ,{" "}
          <ExternalLink href="https://midwestsocialist.com/2019/09/25/building-a-multiracial-working-class-organization-in-chicago-and-beyond/">
            organization building
          </ExternalLink>
          , the relationship between{" "}
          <ExternalLink href="https://midwestsocialist.com/2020/10/30/organizations-in-movement/">
            movements and organizations
          </ExternalLink>
          , among a couple dozen or so other articles for{" "}
          <ExternalLink href="https://midwestsocialist.com/author/ramsin/">
            Midwest Socialist
          </ExternalLink>
          ,{" "}
          <ExternalLink href="https://jacobin.com/2018/12/marxism-socialism-class-struggle-materialism">
            Jacobin
          </ExternalLink>
          ,{" "}
          <ExternalLink href="https://inthesetimes.com/authors/ramsin-canon">
            In These Times
          </ExternalLink>
          ,{" "}
          <ExternalLink href="https://truthout.org/authors/ramsin-canon/">
            Truthout
          </ExternalLink>{" "}
          and other outlets.
        </p>
        <p>
          I work as a worker-side and land use attorney in Chicago and raise the
          number one best dog Rosalita.
        </p>
      </>
    ),
  },
  {
    name: "Riley Woodward-Pratt",
    position: "Democracy Commission",
    bio: (
      <>
        <p>
          During the 2016 Bernie campaign, I heard Cornell West use the old line
          ‘an unorganized socialist is a contradiction in terms,’ but at that
          time, with the feeling of real possibility that was in the air, it hit
          home. So I joined DSA and started our Olympia Local, which I chaired
          for five years as we drafted our bylaws, incorporated, and built our
          organization. During that time, I led our DSA for Bernie campaign in
          2020 in Thurston County (one of the few counties in Washington where
          Bernie won twice) and we successfully converted our portion of DSA’s
          membership bump into a capable and durable leadership layer that has
          persisted despite difficulties. Subsequently, I served on the 2023
          Convention Voting and Elections Committee and chaired our National
          Political Education Committee from 2020-2023 as it was reestablished
          following the 2019 Convention. On NPEC, I sought to build foundational
          structures and practices that will serve DSA in the long term, such as
          a national political education curriculum and a permanent, regular
          program of introductory courses, so that we have a clear statement of
          what unites us as socialists and that members old and new always have
          access to consistent, high-quality presentations of that material.
        </p>
        <p>
          As a member of the Democracy Commission, I hope to ensure we all start
          from a position of inquiry, not policy advocacy. At the end, this
          commission needs to produce recommendations that are widely accepted
          across DSA. Some of those will come through negotiation and compromise
          between existing policy positions, but others, maybe most, will be new
          ideas generated along the way. In DSA, we have a wealth of smart,
          knowledgeable, and creative political thinkers committed to seeing
          socialism win, and I am confident we can accomplish even more together
          than we do as separate factions.
        </p>
      </>
    ),
  },
]

class Candidates2023 extends React.Component {
  state = { visible: false, candidate: null, groupedCandidates: [] }

  componentDidMount() {
    this.setState({
      groupedCandidates: this.getGroupedCandidates(),
    })
  }

  showModal = (candidate) => {
    this.setState({
      visible: true,
      candidate: candidate,
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    })
  }

  images = () => this.props.data.allFile.edges.map((edge) => edge.node)

  image = (candidate) => {
    const name = candidate.name
      .split(" ")
      .join("-")
      .toLowerCase()
      .replace(/\./g, "")
    return this.images().find((image) => image.name === name).childImageSharp
      .fluid
  }

  getGroupedCandidates = () => {
    const positions = []
    const groups = []
    candidates.forEach((candidate) => {
      if (!positions.includes(candidate.position)) {
        positions.push(candidate.position)
        groups.push({ position: candidate.position, candidates: [] })
      }
      groups[groups.length - 1].candidates.push(candidate)
    })

    return groups
  }

  render() {
    return (
      <Layout
        title="DSA Officer Election 2023 "
        color="red"
        heroImageName="index"
        layoutClassName="home"
      >
        <SEO
          title="DSA Officer Election 2023 Candidates"
          ogImage="convention"
        />

        <div className="content-column content-column-candidates">
          <Element name="candidates" id="candidates">
            <h2>Meet the Candidates</h2>
          </Element>

          <div className="candidate-cards">
            {this.state.groupedCandidates.map((group) => (
              <div className="position" key={group.position}>
                <h3>{group.position}</h3>
                {group.candidates.map((candidate, index) => (
                  <div
                    className="candidate-card"
                    onClick={() => this.showModal(candidate)}
                    onKeyPress={() => this.showModal(candidate)}
                    key={candidate.name}
                    role="button"
                    tabIndex={index}
                  >
                    <CandidatePhoto
                      img={this.image(candidate)}
                      alt={candidate.name}
                    />
                    <div className="candidate-card__content">
                      <h3>{candidate.name}</h3>
                      <h4>{candidate.school}</h4>
                      <Button>Read more&hellip;</Button>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="content-column">
          <p>
            At the 2023 DSA National Convention, the convention decided to elect
            a number of leadership roles to help steer DSA. B&R is excited to
            announce our candidates for these positions.
          </p>
          <h2>National Co-Chair</h2>
          <h3>A Mass Audience for Socialism</h3>
          <p>
            In the past few months, DSA has been in the media more than in any
            other time in our history. However much of our time was spent
            fending off bad faith attacks or explaining what our position wasn’t
            rather than putting forward a positive vision for society. This is
            partly because we do not have strong, visible public figures in DSA
            who can bring a socialist message – and a working-class analysis of
            major political events – to a mass audience.
          </p>
          <p>
            As co-chair, I will use my position to publicly agitate
            working-class people toward our politics. This means using every
            opportunity to criticize the Democrats and talk about a workers’
            party, supporting militant rank-and-file labor struggles, and
            plugging national DSA campaigns such No Money for Massacres, Strike
            Ready, and Trans Rights and Bodily Autonomy. Furthermore, our
            co-chairs need to build stronger relationships with our elected
            officials, workplace leaders and other social movement groups.
          </p>
          <h3>Electoral Politics: Building a Workers’ Party</h3>
          <p>
            <i>Agitate for Independent Politics</i>
          </p>
          <p>
            The failures of the Democratic Party are more stark than ever. 80%
            of Americans support a cease-fire in Gaza but just 19% of Democratic
            congress members do. Joe Biden’s genocidal positions have led to
            thousands of people pledging not to support him in 2024, which may
            cost him the election.
          </p>
          <p>
            Now more than ever, people are looking for an alternative and DSA
            must be ready to provide them with one. We need a co-chair who isn’t
            afraid to publicly speak about the corruption and violence of the
            Democratic Party and the need for an independent party.
          </p>
          <p>
            <i>Building a Federal SIO Committee</i>
          </p>
          <p>
            The recent controversy over Gaza has shown the power of having an
            organized Federal Socialists in Office Committee (FSIO). Through our
            coordination with our federal elected officials, we were able to run
            No Money for Massacres phone banks which have had a material impact
            on calls for ceasefire and no Israeli aid, and provided support to
            Rashida Talib after she was unjustly censured.
          </p>
          <p>
            By the 2025 convention, I would like to see a robust FSIO that meets
            regularly with our federal electeds and works closely with them on
            our campaigns. It is crucial that our federal socialists in office
            don’t just see DSA as a coalition partner, but as an organization
            they belong to, whose support is crucial for them to challenge the
            power of the capitalist class.
          </p>
          <p>
            <i>Helping Chapters Run Campaigns and Build SIOs</i>
          </p>
          <p>
            I want to connect the National Electoral Committee (NEC) to the
            day-to-day operations of DSA-endorsed campaigns and help chapters
            form SIOs after they win elections. Members of the NEC should be
            meeting regularly with DSA leaders, campaign staff, and the
            candidates themselves to provide support and guidance on campaigns.
            The NEC should also work closely with chapters to facilitate the
            process of starting local SIO committees.
          </p>
          <h3>DSA Budget: Fundraising for the Future</h3>
          <p>
            <i>A Culture of Giving</i>
          </p>
          <p>
            DSA urgently needs to raise more money to carry out the resolutions
            passed at the convention. While this is a challenging task, there is
            a massive well of untapped fundraising potential in our membership
            and our campaigns.
          </p>
          <p>
            Up to now, we have mostly relied on member dues to sustain us. While
            dues are massively important and should remain our main source of
            income, we rarely ask members or fellow travelers to make general
            donations to DSA. Systematic fundraising is not always built into
            our national campaigns, and it is rarely part of the day-to-day
            operations of local chapters and working groups.
          </p>
          <p>
            In our current financial crisis, we must build a culture of
            fundraising. We must employ the local chapter’s “best and brightest”
            fundraisers to help us get through this difficult financial period.
          </p>
          <p>
            <i>A Concrete Fundraising Plan</i>
          </p>
          <p>These are many proposed tactics to increase our revenue:</p>
          <p>
            <ul>
              <li>
                Adopt electoral campaign-style tactics such as asking members to
                donate directly to DSA, hosting house parties, and asking their
                personal networks to donate
              </li>
              <li>
                Make targeted fundraising asks to pay for specific items (i.e.,
                can you donate to help us run No Money For Massacres
                phonebanks?)
              </li>
              <li>
                Increase outreach to non-member supporters and ask them to
                donate. We should utilize texting services, campaign lists, and
                internet ads to reach this broader network of sympathizers
              </li>
              <li>
                Organize a “fundraising day of action” and ask our celebrity
                members to boost our donation link on their social media
              </li>
            </ul>
          </p>
          <p>
            <i>Paying for the Position</i>
          </p>
          <p>
            As co-chair, I would also personally do a significant amount of
            fundraising through spearheading DSA fundraising efforts, 1:1 calls,
            and organizing fundraisers. We should strive to raise at least the
            equivalent of our salary.
          </p>
          <h3>DSA Labor: The Rank and File Strategy</h3>

          <p>
            I fully support the successful Strike Wave slate and their platform
            for the NLC Steering Committee. I will work closely with the NLC to
            expand DSA’s systematic, national labor organizing, including:
          </p>
          <p>
            <ul>
              <li>
                Consolidate the infrastructure of the Strike Ready campaign into
                a middle layer of DSA leaders
              </li>
              <li>
                Develop worker networks and job pipelines for both DSA and YDSA
                organizers
              </li>
              <li>
                Invest in outward facing electoral projects such as EWOC and RFP
              </li>
            </ul>
          </p>
          <h3>YDSA: Build the Youth Socialist Movement</h3>
          <p>
            DSA is one of the most successful and exciting parts of the
            socialist movement and will sustain DSA for generations to come.
            YDSA organizers are not just the future of the socialist movement,
            they are building it right now. YDSA chapters across the country
            have unionized their campuses, run campaigns for abortion rights or
            free college and organized massive pro-Palestine rallies, and with
            each of these projects recruiting more people to DSA. It is
            immensely strategic for us to invest in YDSA. However, unfortunatly
            YDSA organizers are often treated like “the kids” of DSA, I strive
            to change that as co-chair.
          </p>
          <p>
            <i>Recruit More People to YDSA</i>
          </p>
          <p>
            College students disproportionately identify as socialists, meaning
            there are thousands of young people ready to be organized. As a
            former YDSA leader, I will be in a unique positon to grow YDSA as
            well as DSA. Our goal should be to make YDSA the political home for
            any young person who wants to get involved in left-wing politics.
          </p>
          <p>
            <i>Financial Resources</i>
          </p>
          <p>
            The financial needs of YDSA should be determined by its leadership
            and enacted by the NPC. At the very least, it is crucial that YDSA
            has enough funding to have 2 full-time staffers and member interns
            and fruitful yearly conferences and conventions.
          </p>
          <p>
            <i>Combine the Organizing Power of YDSA and DSA Chapters</i>
          </p>
          <p>
            As a former YDSA representative on NYC-DSA leadership, I understand
            the power of YDSA and DSA chapters working together. As co-chair, I
            would work to create structures for YDSA and DSA chapters to work
            together on campaigns and support each others organizing efforts. I
            would also help support the existing YDSA mentorship pipeline so
            YDSA organizers and transition into DSA organizing once they
            graduate.
          </p>

          <h3>Palestine Organizing: From the River to the Sea</h3>
          <p>
            The genocide happening in Palestine will be one of the defining
            moments of our generation. As socialists, fighting war is both a
            moral obligation and a strategic necessity. The No Money For
            Massacres phone banks have had a concrete impact in Washington and
            pushed Congressmembers to support a ceasefire and oppose aid to
            Israel. However, we should be oriented towards building a mass
            anti-war movement that will last for years to come.
          </p>
          <p>
            <i>Building a Working-Class Anti-War Coalition</i>
          </p>
          <p>
            Multiple DSA chapters have taken the lead in building broad
            coalitions for Palestinian solidarity work, made up of all kinds of
            Palestinian, anti-Zionist Jewish, community and labor organizations.
            These local coalitions can lay the foundation for a strong, durable
            anti-war movement that can unite thousands or even millions of
            working class people in fighting for peace and security.
          </p>
          <p>
            <i>Primary War Hawks</i>
          </p>
          <p>
            Part of our anti-war movement must be primarying elected officials
            who actively support the genocide of Palestine, oppose a ceasefire,
            or support aid to Israel. We must show working class people that we
            will not tolerate unabashed support of war, and demonstrate to
            electeds that their actions (or inaction) have consequences.
          </p>
          <h2>National Labor Commission</h2>
          <p>
            The NLC has been a great corner of DSA to build in for the past year
            and my main goal for the two years to come is to retain that
            positive energy and momentum. I have seen labor work’s ability to
            bring together chapters that appeared irreparably damaged. It has
            been a bright spot in DSA during a time that could have been very
            bleak. Going into our next term we have a strong Solidarity Fund,
            burgeoning subcommittees and a membership looking for ways to
            connect their local work to what we are doing nationally. Below are
            some of my hopes for the years to come.
          </p>
          <p>
            <ol>
              <li>
                <p>
                  Expanding Solidarity- DSA should lean into being the place
                  where union members coordinate actions and share resources. I
                  think this will involve more zoom calls with smaller audiences
                  to bring workers together, using laborsolidarity.com as a hub
                  for resources and contract language, and continuing our
                  nationally coordinated strike support.
                </p>
              </li>
              <li>
                <p>
                  Pipelines and Reform Caucuses- One of the challenges we will
                  face in the coming years is how to keep workers in strategic
                  industries connected and building together over time. To keep
                  our current momentum we will need to continue turning our
                  comrades into coworkers and our coworkers into comrades. I
                  support us embarking on a period of experimentation with
                  different ways of maintaining coordination between workers as
                  they seek to transform their unions from the inside and bring
                  up new organizers.
                </p>
              </li>
              <li>
                <p>
                  Coalition Building- I want our theory of change to inform all
                  the work that DSA does. I think this works best through
                  collaborating with other areas of the org. I think this looks
                  like cross over calls with the International Committee and
                  Green New Deal campaign so that we are able to expand our
                  audience and bring our analysis to the broader DSA membership.
                </p>
              </li>
              <li>
                <p>
                  Active Onboarding and Communication- In the years to come I
                  want members of DSA Labor to feel a greater sense of
                  connection and ownership to our project. We have seen Strike
                  Ready campaign members and members of our industry groups
                  develop this and I think it can be expanded. This looks like
                  creating an onboarding process in which new members receive a
                  phone call that walks them through how they can plug into work
                  and directs them to where they fit.
                </p>
              </li>
            </ol>
          </p>
          <h2>Democracy Commission</h2>
          <p>
            We see a broad desire across DSA for improving our internal
            democratic processes, building towards the goal of a pre-party DSA.
            While there is plenty of disagreement about specific policies, we
            believe that, through study of ourselves and other socialist parties
            and comradely debate and deliberation, we can find sufficient common
            ground to present the 2025 Convention with structural proposals able
            to garner supermajority support. We are confident this
            cross-tendency project will succeed because, despite our
            differences, we all know DSA must succeed if we are to avoid ever
            greater barbarism in our country and around the world.
          </p>
          <ol>
            <li>
              <strong>
                The Democracy Commission is a space for shared inquiry; no group
                should enter the work hoping to win predetermined plans and
                outcomes.
              </strong>{" "}
              The intention of the Democracy Commission is to find solutions
              through an investigation of DSA's internal structures and how
              other parties and organizations across history have constituted
              themselves. This means we do not want to come to the group with a
              list of policies we want to see implemented. Instead, we offer a
              preliminary list of areas we think would be fruitful for
              investigation by the full cross-tendency group. This list is only
              a starting point. Through our research, deliberation, and debate,
              together we can find the keys to strengthening our internal
              democracy and to building the organization our class, and our
              historic mission, deserves.
              <p>
                <ol type="a">
                  <li>
                    What do we mean when we talk about democracy? Are we all
                    talking about the same thing?
                  </li>
                  <li>
                    What does it mean for DSA to be ‘big tent’ or
                    ‘multi-tendency’? How does this work in practice and what
                    are the consequences?
                  </li>
                  <li>What is the role of caucuses in internal democracy?</li>
                  <li>
                    What are the rights and responsibilities of members and how
                    do they get put into practice?
                  </li>
                  <li>
                    How do we connect members in various parts of DSA and make
                    them feel like they’re a part of a national organization?
                  </li>
                  <li>
                    Do we need an intermediate leadership layer between NPC and
                    chapters?
                  </li>
                  <li>
                    WHow do we ensure ongoing and productive national debate and
                    discussion on issues, beyond specific resolutions at
                    Convention?
                  </li>
                  <li>
                    How should executive and legislative power be distributed
                    and balanced in DSA? How do we ensure decisions get
                    translated into action?
                  </li>
                  <li>
                    How do we ensure bodies with delegated power, such as
                    national committees, are subject to democratic oversight
                  </li>
                  <li>
                    What does being a DSA member mean? What minimum political
                    commitments/beliefs do we all share?
                  </li>
                  <li>How do we deal with bureaucracy?</li>
                  <li>
                    How can we make sure senior staff are acting in the
                    democratic interest of our membership?
                  </li>
                  <li>
                    How do we make sure that we can objectively assess our work?
                  </li>
                  <li>
                    What kind of organization is DSA, and what should it be?
                  </li>
                  <li>
                    What does it mean to be a party? Should we be a party?
                  </li>
                </ol>
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Refining a shared concept of internal democracy, as it applies
                  to DSA, should be one of the major tasks of the group.
                </strong>{" "}
                Our internal democracy is not a neutral set of procedures, but
                is linked directly with our purpose as an organization. Even
                within the scope of systems that can be called democratic, what
                may work for a religious congregation, a grocery co-op, or a
                bourgeois political party may not work for us. While DSA members
                and caucuses have differing views of how DSA should act, we
                share a fundamental and deep commitment to building a socialist
                and democratic society and see a strong, functional, and vibrant
                DSA as critical to this project. In determining the best way to
                further democratize DSA, all participants in the Commission
                should be prepared to further debate and develop our visions for
                DSA, while holding firm to the shared vision that unites us all
                as comrades.
              </p>
            </li>
            <li>
              <strong>
                Through years of trial and error from our experiences as
                leaders, organizers, and administrators of a resurgent DSA, B&R
                members have developed shared understandings about what
                democracy means to us.
              </strong>{" "}
              While we do not have a list of easy solutions, we come to these
              questions and the broader work of the Commission with a shared
              sense of what democracy means. Too often within DSA (and even more
              outside it), what ‘democracy’ means is assumed without being
              clearly stated. It is not enough to ‘know it when we see it’; we
              will need a clear vision of what we are working towards.
              <ol type="a">
                <li>Democracy is about people having power</li>
                <li>Democracy requires decisions be translated into action</li>
                <li>
                  Democratic organizations have shared common principles/program
                  and definite limits/boundary issues
                </li>
                <li>
                  In order to build the socialist movement in the US, DSA must
                  remain a broad, multi-tendency body united in a shared
                  commitment to winning power for the working class
                </li>
                <li>
                  Multi-tendency democracy means members must be able to
                  organize caucuses
                </li>
                <li>
                  Democracy is primarily a process of collective decision
                  making, not individual rights; that is, any rights possessed
                  by individuals are justified by their utility to egalitarian
                  collective decision-making
                </li>
                <li>
                  The core of democracy is in social relationships and
                  collective action, not in rules; more meetings and process
                  does not equal more democracy
                </li>
                <li>
                  Nonetheless, democracy requires clear and transparent
                  structures and systems
                </li>
                <li>Effective democratic organizing requires leadership</li>
                <li>
                  Effective democratic leaders must be closely connected to
                  their constituencies
                </li>
                <li>
                  Democracy depends on direct, honest, comradely debate to
                  function
                </li>
              </ol>
            </li>
          </ol>

          {/* <h3 style={{ textAlign: "center" }}>
            Do you support Bread and Roses' vision for YDSA?{" "}
            <ExternalLink href="https://forms.gle/KkgCgUDFrrxMp34b6">
              Sign here!
            </ExternalLink>
          </h3> */}
          {/* <h3 style={{ textAlign: "center" }}>
            Find our official voting guide at:{" "}
            <ExternalLink href="https://bit.ly/breadandroses-2022">
              https://bit.ly/breadandroses-2022
            </ExternalLink>
          </h3> */}
        </div>

        <Modal
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          width={600}
        >
          {this.state.candidate && (
            <>
              <h2>
                {this.state.candidate.name}, {this.state.candidate.school}
              </h2>
              <h3>
                {/* {this.state.candidate.pronouns},  */}
                {this.state.candidate.position}
              </h3>
              <CandidatePhoto
                img={this.image(this.state.candidate)}
                alt={this.state.candidate.name}
              />
              {this.state.candidate.bio}
            </>
          )}
        </Modal>
      </Layout>
    )
  }
}

const CandidatePhoto = ({ img, alt }) => (
  <Img className="candidate-photo" fluid={img} alt={alt} />
)

export const query = graphql`
  query Convention2020HeadshotsQuery {
    allFile(filter: { absolutePath: { glob: "**/officers-2023/*" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 150, maxHeight: 150) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`

export default Candidates2023
